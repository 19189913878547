<template>
  <v-container>
    <div class="text-center">
      <v-snackbar
          multi-line
          width="500px"
          centered
          shaped
          top
          v-model="snackbarStatus"
          :timeout="snackbarTimeout"
          :color="snackbarColor"
      >
        <p v-html="snackbarText" style="margin-bottom: 0!important;"></p>
        <template v-slot:action="{ attrs }">
          <v-btn
              text
              v-bind="attrs"
              @click="snackbarStatus = false"
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>

    <v-row v-if="operation === null">
      <v-col style="justify-content: center; display: flex">
        <v-btn rounded @click="rtmpDialog = true" color="#161853" class="white--text">ایجاد جلسه RTMP</v-btn>
      </v-col>
      <v-col style="justify-content: center; display: flex">
        <v-btn rounded @click="startMeeting()" color="#161853" class="white--text">ایجاد جلسه</v-btn>
      </v-col>
      <v-col style="justify-content: center; display: flex">
        <v-btn rounded @click="operation = 'join'" color="#ec255a" class="white--text">پیوستن به جلسه</v-btn>
      </v-col>
    </v-row>
    <v-row v-else-if="operation === 'join'">
      <v-col cols="12" xl="10" lg="10" md="10" sm="9" xs="12">
        <v-text-field color="red" v-model="meetingId" placeholder="لینک"></v-text-field>
      </v-col>
      <v-col cols="12" xl="2" lg="2" md="2" sm="3" xs="12" style="justify-content: center; align-items: center; display: flex">
        <v-btn color="#161853" @click="joinMeeting" :disabled="!meetingId" class="mx-1 white--text">پیوستن</v-btn>
        <v-btn color="#ec255a" @click="operation = null" class="mx-1 white--text">بازگشت</v-btn>
      </v-col>
    </v-row>

    <v-dialog
        v-model="rtmpDialog"
        width="500"
    >
      <v-card>
        <v-card-title>
          آموزش استفاده از سرویس RTMP
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col>
              <p>
                برای استفاده از سرویس RTMP ابتدا نرم افزار OBS Studio را از لینک زیر دانلود کنید<br>
                <a href="https://obsproject.com/download">دانلود نرم افزار OBS Studio</a>
              </p>
              <p>سپس از قسمت Setting وارد Stream شوید</p>
              <p>از لیست Service، گزینه ی Custom را انتخاب کنید</p>
              <p>در قسمت Server، آدرس زیر را وارد کنید</p>
              <p style="direction: ltr!important; text-align: left">
                Server : <br> rtmp://88.135.36.51/live
                <v-btn x-small fab class="mx-1" color="#161853" @click="CopyToClipboard('rtmp://88.135.36.51/live')">
                  <v-icon color="white">mdi-content-copy</v-icon>
                </v-btn>
              </p>
              <p>در قسمت Stream Key، مقدار زیر را وارد کنید</p>
              <p style="direction: ltr!important; text-align: left">
                Stream Key : <br> {{ $store.state.user.rtmp_link }}?token={{ $store.state.user.token }}
                <v-btn x-small fab class="mx-1" color="#161853"
                       @click="CopyToClipboard($store.state.user.rtmp_link + '?token=' + $store.state.user.token)">
                  <v-icon color="white">mdi-content-copy</v-icon>
                </v-btn>
              </p>
              <p style="color: red!important;">مقدار روبروی کلمه token به منزله رمز عبور شماست، آن را در اختیار هیچکس قرار ندهید!</p>
              <p>روی دکمه OK کلیک کنید</p>
              <p>شما آماده استریم هستید،‌ به محض اینکه روی دکمه Start Streaming کلیک کنید،‌ استریم آغاز می شود</p>
              <p>شناسه استریم شما : </p>
              <p style="direction: ltr;text-align: left">
                {{ $store.state.user.rtmp_link }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
              color="#EC255A"
              text
              @click="rtmpDialog = false"
          >
            بستن
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: 'Meeting',
  data() {
    return {
      operation: null,
      meetingId: null,
      rtmpDialog: false
    };
  },

  methods: {
    startMeeting() {
      window.axios.get('user/meet/start').then((res) => {
        window.location.replace('meeting/' + res.data.data.unique_channel)
        this.operation = 'start';
      }).catch((err) => {
        this.openSnackbar(err.response.data.message, '#EC255A')
      })
    },

    joinMeeting() {
      if (this.meetingId.includes('RTMP-')) {
        window.axios.get('/user/rtmp/join-check/' + this.meetingId).then(() => {
          window.location.replace('rtmp/' + this.meetingId)
        }).catch((err) => {
          if (err.response.status === 422) {
            this.showValidationErrors(err.response.data.errors)
          } else if (err.response.status === 400 || err.response.status === 404) {
            this.openSnackbar('جلسه ی مورد نظر وجود ندارد', '#EC255A')
          }
        })
      } else {
        window.axios.get('/user/meet/join-check?unique_channel=' + this.meetingId).then(() => {
          window.location.replace('meeting/' + this.meetingId)
        }).catch((err) => {
          if (err.response.status === 422) {
            this.showValidationErrors(err.response.data.errors)
          } else if (err.response.status === 400 || err.response.status === 404) {
            this.openSnackbar('جلسه ی مورد نظر وجود ندارد', '#EC255A')
          }
        })
      }
    },

    CopyToClipboard(text) {
      navigator.clipboard.writeText(text);
    }
  }
};
</script>

<style scoped>
.bordered-card {
  border: 1px solid black;
  border-radius: 15px!important;
  padding: 10px;
}
</style>
